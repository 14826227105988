import { Theme, alpha, useTheme, SxProps } from '@mui/material';
import { Link } from '@remix-run/react';

import IconButton from '~/components/atoms/IconButton';
import Logo from '~/components/atoms/Logo';
import { mergeSx } from '~/utils/mui';

interface HomeButtonProps {
    variant?: 'plus' | 'square';
    logoSx?: SxProps<Theme>;
    sx?: SxProps<Theme>;
}

const HomeButton = ({ variant, logoSx, sx }: HomeButtonProps) => {
    const theme = useTheme();

    return (
        <IconButton
            component={Link}
            to="/"
            aria-label="logo"
            sx={mergeSx(
                {
                    px: 3,
                    display: 'flex',
                    height: '52px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '&:hover': { backgroundColor: alpha(theme.palette.secondary.main, 0.1) },
                },
                sx,
            )}
        >
            <Logo variant={variant} sx={logoSx} />
        </IconButton>
    );
};

export default HomeButton;
